<template>
  <tr :class="$style.root">
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="name"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <date-picker
            v-model="dateStart"
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-model="status"
            :items="downloadStatuses"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <date-picker
            v-model="dateAvailable"
            @input="onChange"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import { get, debounce, pickBy } from 'lodash';

import DatePicker from '@/components/DatePickerRange/DatePickerRange';
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'DownloadsListFilter',
  components: {
    DatePicker,
  },
  props: {
    value: {type: Object},
  },
  data() {
    return {
      dateStart: get(this.value, 'dateStart', ''),
      name: get(this.value, 'name', ''),
      dateAvailable: get(this.value, 'dateAvailable', ''),
      status: get(this.value, 'status', ''),
    }
  },
  computed: {
    ...mapGetters({
      downloadStatuses: 'downloads/downloadStatuses',
    })
  },
  methods: {
    ...mapActions({
      'fetchDownloadStatuses': 'downloads/fetchDownloadStatuses',
    }),
    onChange: debounce(function () {
      this.$emit('input', pickBy({
        dateStart: this.dateStart,
        name: this.name,
        dateAvailable: this.dateAvailable,
        status: this.status,
      }, Boolean));
    }, 600),
  },
  mounted() {
    this.fetchDownloadStatuses();
  },
}
</script>

<style module lang="scss">
.root {
  background: none !important;

  .control {
    width: 150px;
  }
}
</style>
